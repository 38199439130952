import React from "react";
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next";

import { DefaultLayout } from "../../layouts/DefaultLayout";

export const ContactSuccessPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <DefaultLayout>
      <Helmet>
        <title>{t("nav.about")} | {t("company.name")}</title>
      </Helmet>
      About
    </DefaultLayout>
  );
};
